import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import "./assets/css/index.css";
import VueI18n from "vue-i18n";
import { zhCn } from "./assets/i18n/zhCn";
import { zhEn } from "./assets/i18n/zhEn";
import { zhSp } from "./assets/i18n/zhSp";
import { zhPt } from "./assets/i18n/zhPt";
import VueClipboard from 'vue-clipboard2'
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
// 防止重复点击  标签处加 v-preventReClick
import preventReClick from './store/preventReClick';

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.use(VueClipboard)
Vue.use(require('vue-moment'));
Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(VueI18n);

const lang = localStorage.getItem("i18n_lang");
const i18n = new VueI18n({
  // 默认使用语言，this.$i18n.locale
  locale: lang || "zh-en",
  //报错时加上这个参数可以取消警告
  silentTranslationWarn: true,
  messages: {
    "zh-cn": zhCn,
    "zh-en": zhEn,
    "zh-sp": zhSp,
    "zh-pt": zhPt,
  }
});

if (lang) {
  if (lang == "zh-cn") {
    Locale.use("zh-CN", zhCN);
  } else {
    Locale.use("en-US", enUS);
  }
} else {
  Locale.use("en-US", enUS);
}

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
