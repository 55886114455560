<template>
  <div>
    <div class="market">
      <h3 class="market-title">{{ $t("智能委托市场") }}</h3>
      <div class="swipe-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in bannerList" :key="item.id"
            ><img class="banner-img" :src="item.image" alt=""
          /></van-swipe-item>
        </van-swipe>
      </div>
      <van-notice-bar
        left-icon="volume-o"
        :text="this.content"
        mode="link"
        @click="goGonggao"
      />
      <div class="tab-box">
        <van-tabs @click="setCategory">
          <van-tab
            :title="item.name"
            v-for="item in robotCategoryList"
            :key="item.id"
          >
          </van-tab>
        </van-tabs>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('没有更多了')"
          @load="getList"
        >
          <div
            class="tab-cell"
            v-for="(item, index) in robotList"
            :key="index"
            @click="goBuy(item.id)"
          >
            <div class="tab-cell-left">
              <div>
                <img class="left-img" :src="item.category.image" alt="" />
              </div>
              <div class="left-text">
                <p class="left-text1">
                  {{ item.days }}
                  <span v-if="item.days == 1">{{ $t("天") }}</span
                  ><span v-if="item.days !== 1">{{ $t("天数") }}</span>
                </p>
                <p class="left-text2">{{ $t("委托周期") }}</p>
              </div>
            </div>
            <div class="tab-cell-right">
              <p class="right-text1">{{ item.income }}%</p>
              <p class="right-text2">{{ $t("预期月收益") }}</p>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <kefu></kefu>
    <router-view />
    <van-tabbar route>
      <van-tabbar-item replace to="/" icon="chart-trending-o">{{
        $t("市场")
      }}</van-tabbar-item>
      <van-tabbar-item replace to="/team" icon="friends-o">{{
        $t("团队")
      }}</van-tabbar-item>
      <van-tabbar-item replace to="/trade" icon="exchange">{{
        $t("交易")
      }}</van-tabbar-item>
      <van-tabbar-item replace to="/wallet" icon="paid">{{
        $t("钱包")
      }}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {
  apiGetCategoryType,
  apiList,
  apiAnnouncement,
} from "../../request/api";
import kefu from "../../components/kefu.vue";
export default {
  name: "Home",
  components: { kefu },
  data() {
    return {
      type: "sys_robot_list",
      type1: "banner",
      robotCategoryList: [],
      bannerList: [],
      category_id: "0",
      page: "1",
      list_rows: "10",
      robotList: [],
      content: "",
      loading: false,
      finished: false,
    };
  },
  created() {
    var token = localStorage.getItem("key");
    if (!token) {
      this.$router.replace("/login");
    }
    apiGetCategoryType({
      type: this.type,
    }).then((res) => {
      this.robotCategoryList = res.data;
      // console.log(this.robotCategoryList);
      this.category_id = this.robotCategoryList[0]
        ? this.robotCategoryList[0].id
        : 0;
      this.getList(true);
    });
    apiGetCategoryType({
      type: this.type1,
    }).then((res) => {
      // console.log(res);
      this.bannerList = res.data;
    });
    apiAnnouncement({
      page: this.page,
      list_rows: this.list_rows,
    }).then((res) => {
      this.content = res.data.rows[0].title;
      // console.log(this.content);
    });
  },
  methods: {
    setCategory(index, title) {
      // console.log(index,title, this.robotCategoryList[index].id);
      this.category_id = this.robotCategoryList[index].id;
      this.getList(true);
    },
    getList(isOne) {
      this.loading = true;
      if (isOne === true) {
        this.page = 1;
        this.finished = false;
        // 清空数据
        this.dataList = [];
      }
      if (this.category_id == 0) {
        return false;
      }
      apiList({
        category_id: this.category_id,
        page: this.page,
        list_rows: this.list_rows,
      }).then((res) => {
        // console.log(res);

        let rows = res.data.rows;
        if (rows.length > 0) {
          if (this.page == 1) {
            this.robotList = rows;
          } else {
            rows.forEach((item) => {
              this.robotList.push(item);
            });
          }

          this.loading = false;
          if (this.page * this.list_rows >= res.data.total) {
            this.finished = true;
          }
          this.page++;
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    goGonggao() {
      this.$router.push("/gonggao");
    },
    goBuy(id) {
      this.$router.push({
        path: "/buy",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.market {
  padding: 0 30px;
}
.market-title {
  font-size: 22px;
  font-weight: normal;
  text-align: left;
  margin: 20px 0;
}
.swipe-box {
  margin-bottom: 10px;
}
.banner-img {
  width: 100%;
}
.van-notice-bar {
  padding: 0;
}
.tab-cell {
  display: flex;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin-top: 10px;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  padding: 15px 10px;
}
.tab-cell-left {
  display: flex;
  align-items: center;
  text-align: center;
}
.left-img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}
.left-text1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.left-text2 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.right-text1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #28a17c;
}
.right-text2 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  padding: 0 10px;
}
:deep(.van-tabs__line) {
  background-color: #345de9;
}
:deep(.van-tab--active) {
  font-weight: 700;
  color: #345de9;
}
.van-tabs {
  margin: 10px 0;
}
</style>
