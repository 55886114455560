import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/market/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/buy",
    name: "buy",
    component: () => import("../views/market/buy.vue"),
  },
  {
    path: "/gonggao",
    name: "gonggao",
    component: () => import("../views/market/gonggao.vue"),
  },
  {
    path: "/gonggao-detail",
    name: "gonggao-detail",
    component: () => import("../views/market/gonggao-detail.vue"),
  },
  {
    path: "/kefu",
    name: "kefu",
    component: () => import("../components/kefu.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
  },
  {
    path: "/team",
    name: "team",
    component: () => import("../views/team/index.vue"),
  },
  {
    path: "/dengji",
    name: "dengji",
    component: () => import("../views/team/dengji.vue"),
  },
  {
    path: "/income-bill",
    name: "income-bill",
    component: () => import("../views/team/income-bill.vue"),
  },
  {
    path: "/team-benefits",
    name: "team-benefits",
    component: () => import("../views/team/team-benefits.vue"),
  },
  {
    path: "/zhitui-num",
    name: "zhitui-num",
    component: () => import("../views/team/zhitui-num.vue"),
  },
  {
    path: "/team-num",
    name: "team-num",
    component: () => import("../views/team/team-num.vue"),
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("../views/team/invite.vue"),
  },
  {
    path: "/trade",
    name: "trade",
    component: () => import("../views/trade/index.vue"),
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("../views/wallet/index.vue"),
  },
  {
    path: "/bill",
    name: "bill",
    component: () => import("../views/wallet/bill.vue"),
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("../views/wallet/recharge.vue"),
  },
  {
    path: "/recharge-confirmation",
    name: "recharge-confirmation",
    component: () => import("../views/wallet/recharge-confirmation.vue"),
  },
  {
    path: "/dcRechargeBill",
    name: "dcRechargeBill",
    component: () => import("../views/wallet/dcRechargeBill.vue"),
  },
  {
    path: "/fcRechargeBill",
    name: "fcRechargeBill",
    component: () => import("../views/wallet/fcRechargeBill.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/wallet/withdraw.vue"),
  },
  {
    path: "/dcWithdrawalBill",
    name: "dcWithdrawalBill",
    component: () => import("../views/wallet/dcWithdrawalBill.vue"),
  },
  {
    path: "/fcWithdrawalBill",
    name: "fcWithdrawalBill",
    component: () => import("../views/wallet/fcWithdrawalBill.vue"),
  },
  {
    path: "/bank-account",
    name: "bank-account",
    component: () => import("../views/wallet/bank-account.vue"),
  },
  {
    path: "/addBankCard",
    name: "addBankCard",
    component: () => import("../views/wallet/addBankCard.vue"),
  },
  {
    path: "/modifyBankCard",
    name: "modifyBankCard",
    component: () => import("../views/wallet/modifyBankCard.vue"),
  },
  {
    path: "/security-center",
    name: "security-center",
    component: () => import("../views/wallet/security-center.vue"),
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("../views/wallet/changePassword.vue"),
  },
  {
    path: "/changePhone",
    name: "changePhone",
    component: () => import("../views/wallet/changePhone.vue"),
  },
  {
    path: "/currencyUnit",
    name: "currencyUnit",
    component: () => import("../views/wallet/currencyUnit.vue"),
  },
  {
    path: "/lang",
    name: "lang",
    component: () => import("../views/wallet/lang.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/h5/',
  routes
})

export default router
