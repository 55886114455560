/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import QS from "qs";
import store from "../store/index";
import router from "../router";


// 正式服
// export const baseURL = "https://api.coinsource.vip/";
// 演示服
export const baseURL = "https://znwt.ykvip.live/";


axios.defaults.baseURL = baseURL;

// 请求超时时间
axios.defaults.timeout = 10000;


axios.interceptors.request.use(config => {
  let token = localStorage.getItem("key")
  if (token) {
    config.headers.token = token;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },

  (error) => {
    if (error.response.status) {
      switch (error.response.status) {

        case 401:
          router.replace({
            path: "/login",
          });
          break;

        case 403:
          this.$toast({
            message: "Login expired, please login again.",
            duration: 1000,
            forbidClick: true,
          });
          localStorage.removeItem("key");

          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }, 1000);
          break;

        case 404:
          this.$toast({
            message: "unknown error",
            duration: 1500,
            forbidClick: true,
          });
          break;

        default:
          this.$toast({
            message: error.response.msg,
            duration: 1500,
            forbidClick: true,
          });
      }
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  if (params) {
    params.LANG = localStorage.getItem("i18n_lang") || "zh-en";
  } else {
    params = {
      LANG: localStorage.getItem("i18n_lang") || "zh-en"
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
