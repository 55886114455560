// 英语
const zhEn = {
    "欢迎回来!": "Welcome back!",
    "手机号": "Phone number",
    "请输入": "Please enter",
    "验证码": "Verification code",
    "获取验证码": "Get verification code",
    "登录": "Login",
    "注册": "Register",
    "请输入手机号码": "Please enter the phone number",
    "请输入验证码": "Please enter verification code",

    "加载中": "Loading",
    "密码": "Password",
    "邀请码": "Invitation code",
    "交易密码": "Transaction password",
    "确定": "Confirm",
    "请输入密码": "Please enter password",
    "请输入邀请码": "Please enter the invitation code",
    "请输入交易密码": "Please enter transaction password",

    "买入": "Buy In",
    "可用": "Available Amount",
    "委托交易中": "Entrusted transaction",
    "委托金额": "Entrusted amount",
    "全部": "All",
    "预计1年收益可达": "Expected 1 year income",
    "大约等于": "Approximately equal to",
    "确认": "Confirm",
    "您的可用金额为0": "Your available amount is 0",

    "请输入金额": "Please enter the amount",
    "超过请输入交易密码": "Exceeded available balance",
    "公告详情": "Announcement Details",
    "公告": "Announcement",
    "没有更多了": "No more",
    "智能委托市场": "Entrusted Market",
    //翻译周期
    "委托周期": "Entrusted Cycle",
    //翻译收益率
    "预期月收益": "Estimated Monthly Income",

    "市场": "Market",
    "团队": "Team",
    "交易": "Transaction",
    "钱包": "Wallet",
    "等级": "Level",
    "当前等级": "Current level",
    "收益账单": "Income bill",
    "交易单号": "Transaction number",

    "收益时间": "Profit time",
    "收益金额": "Income amount",
    "暂无数据": "No data",
    "交易收益": "Transaction Income",
    "总收益": "Total Revenue",
    "今日收益": "Today's Earnings",
    "下一级": "Next level",
    "团队收益": "Team benefits",
    "直推人数": "Direct Surbordinates Members",

    "团队人数": "Total Team Members",
    "今日新增": "Today New Members",
    "邀请好友": "Invite Friends",
    "收入排行榜": "Revenue Ranking",
    "ID": "ID",
    "高收益，当然一起赚": "High income, of course earn together",
    "邀请好友获得每个直属下属10%的收益,如果您邀请10个朋友,您将获得100%.": "Invite friends to get 10% of each direct subordinate, if you invite 10 friends, you will get 100%.",
    "立即邀请": "Invite Now",

    "面对面邀请": "Face to face invitation",
    "保存图片": "Save Picture",
    "邀请码": "Invitation code",
    "注册链接": "Registration link",
    "享直推1级交易收益": "Enjoy direct push level 1 trading income",
    "享2-10级交易收益": "Enjoy level 2-10 trading income",
    "复制成功": "Copy successfully",
    "复制失败": "Replication failed",

    "下级用户名称": "Subordinate user name",
    "下级层级": "Lower level",
    "运行人数": "Number of people running",
    "注册时间": "Registration time",
    "机器人列表": "Robot list",
    "订单号": "Order number",
    "产品名称": "Product name",
    "天数": "Days",

    "天": "Day",
    "币种": "Currency",
    "比率": "Ratio",
    "购买时间": "Purchase time",
    "到期时间": "Expire date",
    "进行中": "processing",
    "完成": "finish",
    "添加机器人": "Add Robot",

    "添加银行卡": "Add a bank card",
    "银行名称": "Bank Name",
    "银行账户": "Bank Account",
    "账户姓名": "Account Name",
    "请选择银行": "Please select a bank",
    "银行账户不能为空": "Bank account cannot be empty",
    "账户姓名不能为空": "Account name cannot be empty",
    "账户": "Account",
    "修改银行卡": "Modify bank card",

    "删除银行卡": "Delete card",
    "+添加银行账户": "+ Add bank account",
    "提示": "Hint",
    "确认删除该银行卡吗？": "Are you sure you want to delete this card?",
    "账单": "Bill",
    "时间": "Time",
    "账单类型": "Bill Type",
    "充值": "Recharge",
    "提现": "Withdraw",

    "结算": "Settlement",
    "变化前金额": "Amount before change",
    "变化后金额": "Amount after change",
    "变化金额": "Change amount",
    "备注": "Remark",
    "添加时间": "Add time",
    "修改交易密码": "Modify transaction password",
    "新密码": "New password",
    "确认新密码": "Confirm the new password",
    "请输入新密码": "Please enter a new password",

    "请确认新密码": "Please confirm new password",
    "两次密码不一致": "The two passwords do not match",
    "手机号码不能为空": "Phone number can not be blank",
    "修改手机号码": "Modify mobile number",
    "当前绑定的手机号码": "The currently bound mobile number",
    "新手机号码": "New mobile phone number",
    "货币单位": "Currency Unit",
    "数字货币充值账单": "Digital currency recharge bill",

    "充值单号": "Recharge order number",
    "传输网络": "Transporting network",
    "应到金额": "Amount due",
    "实到金额": "Actual amount",
    "法币金额": "Fiat Amount",
    "数字币种名称": "Digital currency name",
    "法定币种名称": "Fiat currency name",
    "到账时间": "Arrival time",
    "数字货币提现账单": "Digital Currency Withdrawal Bill",

    "提现单号": "Withdrawal order number",
    "转账网络": "Transfer network",
    "申请金额": "Application Amount",
    "手续费": "Handling fee",
    "到账法币金额": "Amount in fiat currency",
    "到账钱包地址": "Account wallet address",
    "到账银行": "Bank account",

    "失败时间": "Failure time",
    "完成时间": "Complete time",
    "法定货币充值账单": "Fiat currency top-up bill",
    "法定货币提现账单": "Fiat currency withdrawal bill",
    "设置": "Set up",
    "安全中心": "Security center",
    "切换语言": "Language Selection",
    "在线客服": "Online service",

    "退出账号": "Sign out of account",
    "总资产": "Total assets",
    "可用余额": "Available Balance",
    "委托交易": "Entrusted transaction",
    "确认退出账号吗？": "Are you sure you want to log out?",
    "语言设置": "Language settings",
    "修改银行卡": "Modify bank card",
    "请选择银行": "Please select a bank",
    "银行账户不能为空": "Bank account cannot be empty",

    "账户姓名不能为空": "Account name cannot be empty",
    "数字货币充值": "Digital currency recharge",
    "法定货币充值": "Fiat currency recharge",
    "充值金额": "Recharge amount",
    "充币金额": "Deposit amount",
    "订单确认": "Order Confirmation",
    "数量": "Quantity",
    "渠道": "Channel",
    "请输入充币金额": "Please enter the deposit amount",
    "请选择转账网络": "Please select a transfer network",
    "请输入充值金额": "Please enter the recharge amount",
    "汇率": "Exchange rate",

    "数字货币提现": "Digital currency withdrawal",
    "法定货币提现": "Fiat currency withdrawal",
    "提币数量": "Withdrawal amount",
    "地址": "Address",
    "提现金额": "Withdrawal Amount",
    "请输入提币数量": "Please enter the withdrawal amount",
    "可用余额不足": "Insufficient available balance",
    "请输入地址": "Please enter address",
    "请选择银行账户": "Please select a bank account",
    "请输入提现金额": "Please enter the withdrawal amount",
    "手续费减免": "Fee waiver",
    "到账金额": "Amount received",
    "忘记密码": "Forgot password",

    "美元": "Dollar",
    "欧元": "EUR",
    "人民币": "RMB",
    "越南盾": "Vietnamese Dong",
    "泰铢": "Thai Baht",
    "卢比": "Rupee",
    "韩币": "Korean Won",
    "林吉特": "Ringgit",
    "人": "People",
    "收益": "Income",

    "DD 天 HH:mm:ss": "DD Day HH:mm:ss",
    "DD 天数 HH:mm:ss": "DD Days HH:mm:ss",
    "交易收入": "Transaction Income",
    "预估月收入": "Estimated Monthly Income",
    "开始时间": "Creation Time",
    "结束时间": "End Time",
    "超过可用余额": "Exceeded available balance",
    "金额": "Amount",



};
export { zhEn };