import { get, post } from "./http.js";
//注册接口
export const apiRegister = (p) => post("/api/user/register", p);
// 发送验证码
export const apiSend = (p) => post("/api/sms/send", p);
// 手机验证码登录
export const apiLogin = (p) => post("/api/user/mobilelogin", p);
// 类别接口
export const apiGetCategoryType = (p) => post("/api/common/getCategoryType", p);
//  根据币种类型获取列表接口
export const apiList = (p) => post("/api/index/index", p);
// 公告列表
export const apiAnnouncement = (p) => post("/api/ad/index", p);
// 公告详情 
export const apiADetails = (p) => post("/api/ad/info", p);
// 获取产品详情 
export const apiInfo = (p) => post("/api/index/info", p);
//  买入委托产品接口 
export const apibuyProduct = (p) => post("/api/tradelog/buynow", p);
// 交易列表
export const apitradeList = (p) => post("/api/tradelog/index", p);
// 获取用户信息
export const apigetUserData = (p) => post("/api/user/getUserData", p);
// 退出登录
export const apiLoginout = (p) => post("/api/user/logout", p);
// 获取交易收益统计数据 
export const apiGetStatisticsData = (p) => post("/api/team/getStatisticsData", p);
// 获取等级介绍 
export const apiGetLevelList = (p) => post("/api/team/getLevelList", p);
//  获取交易收益记录
export const apiGetTradeMoney = (p) => post("/api/team/getTradeMoney", p);
//  获取团队收益记录
export const apiGetCommissionMoney = (p) => post("/api/team/getCommissionMoney", p);
// 获取直推人员记录 
export const apiGetDirectlyUnderUser = (p) => post("/api/team/getDirectlyUnderUser", p);
//  获取我的团队人员列表
export const apiGetMyItemList = (p) => post("/api/team/getMyItemList", p);
// 获取分享数据
export const apiGetShare = (p) => post("/api/team/getShare", p);
// 获取排行榜
export const apiGetRankingList = (p) => post("/api/team/getRankingList", p);
// 账单列表
export const apiBillList = (p) => post("/api/billlog/index", p);
// 银行卡列表
export const apiBankList = (p) => post("/api/bank/index", p);
//  添加编辑银行卡
export const apiAddCard = (p) => post("/api/bank/add_edit", p);
// 银行卡详情
export const apiBankInfo = (p) => post("/api/bank/info", p);
// 删除银行卡
export const apiBankDelete = (p) => post("/api/bank/delete", p);
// 修改交易密码
export const apiResetPwd = (p) => post("/api/user/resetdealpwd", p);
// 修改手机号
export const apiChangeMobile = (p) => post("/api/user/changemobile", p);
//  获取配置
export const apiGetConfig = (p) => post("/api/common/getConfig", p);
// 申请提现
export const apiAddWithdraw = (p) => post("/api/withdraw/addWithdraw", p);
// 提现账单列表
export const apiWithdrawBill = (p) => post("/api/withdraw/index", p);
// 提交充值
export const apiAddRecharge = (p) => post("/api/recharge/addRecharge", p);
//获取支付渠道
export const apiPayTypeList = (p) => post("/api/recharge/getPayTypeList", p);
//  充值账单列表
export const apiRechargeBill = (p) => post("/api/recharge/index", p);
//  切换货币单位
export const apiChangeCurrency = (p) => post("/api/user/changecurrency", p);
// 上传文件
export const apiUpload = (p) => post("/api/common/upload", p);
//  人工审核 
export const apiChageType = (p) => post("/api/recharge/chageType", p);
