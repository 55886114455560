// 葡萄牙
const zhPt = {
    "欢迎回来!": "Bem vindo de volta!",
    "手机号": "Número de telefone",
    "请输入": "Por favor, insira",
    "验证码": "Código de verificação",
    "获取验证码": "Obter código de verificação",
    "登录": "Conecte-se",
    "注册": "Registro",
    "请输入手机号码": "Por favor, insira o número de telefone",
    "请输入验证码": "Por favor insira o código de verificação",

    "加载中": "Carregando",
    "密码": "Senha",
    "邀请码": "Código de Convite",
    "交易密码": "Senha de transação",
    "确定": "Claro",
    "请输入密码": "Por favor, digite a senha",
    "请输入邀请码": "Por favor, insira o código do convite",
    "请输入交易密码": "Por favor, digite a senha da transação",

    "买入": "Comprar",
    "可用": "Acessível",
    "委托交易中": "Transação confiada",
    "委托金额": "Valor da comissão",
    "全部": "Tudo",
    "预计1年收益可达": "Renda esperada de 1 ano",
    "大约等于": "Aproximadamente igual a",
    "确认": "Confirme",
    "您的可用金额为0": "Seu valor disponível é 0",

    "请输入金额": "Por favor, insira o valor",
    "超过请输入交易密码": "Por favor, digite a senha da transação",
    "公告详情": "Detalhes do anúncio",
    "公告": "Anúncio",
    "没有更多了": "Não mais",
    "智能委托市场": "Mercado de comissão inteligente",
    //翻译周期
    "委托周期": "Ciclo",
    //翻译收益率
    "预期月收益": "Taxa de retorno",

    "市场": "Mercado",
    "团队": "Equipe",
    "交易": "Troca",
    "钱包": "Carteira",
    "等级": "Avaliar",
    "当前等级": "Nível atual",
    "收益账单": "Conta de renda",
    "交易单号": "Número da transação",

    "收益时间": "Tempo de lucro",
    "收益金额": "Valor da renda",
    "暂无数据": "Sem dados",
    "交易收益": "Renda de negociação",
    "总收益": "Rendimento total",
    "今日收益": "Ganhos hoje",
    "下一级": "Próximo nível",
    "团队收益": "Benefícios da equipe",
    "直推人数": "Número de envio direto",

    "团队人数": "Tamanho da equipe",
    "今日新增": "Adicionado hoje",
    "邀请好友": "Convide amigos",
    "收入排行榜": "Tabela de classificação de renda",
    "ID": "EU IRIA",
    "高收益，当然一起赚": "Renda alta, claro que ganham juntos",
    "邀请好友获得每个直属下属10%的收益,如果您邀请10个朋友,您将获得100%.": "Convide amigos para obter 10% de cada subordinado direto, se você convidar 10 amigos, você receberá 100%.",
    "立即邀请": "Convide agora",

    "面对面邀请": "Convite cara a cara",
    "保存图片": "Salvar foto",
    "邀请码": "Código de Convite",
    "注册链接": "Link de registro",
    "享直推1级交易收益": "Aproveite a receita de negociação direta de nível 1",
    "享2-10级交易收益": "Aproveite a receita de negociação de nível 2-10",
    "复制成功": "Copiar com sucesso",
    "复制失败": "Falha na replicação",

    "下级用户名称": "Nome de usuário subordinado",
    "下级层级": "Nível mais baixo",
    "运行人数": "Número de pessoas correndo",
    "注册时间": "Horário de registro",
    "机器人列表": "Lista de robôs",
    "订单号": "Número do pedido",
    "产品名称": "Nome do Produto",
    "天数": "Dias",

    "天": "Dia",
    "币种": "Moeda",
    "比率": "Razão",
    "购买时间": "Tempo de compra",
    "到期时间": "Data de validade",
    "进行中": "Em processamento",
    "完成": "Terminar",
    "添加机器人": "Adicionar bot",

    "添加银行卡": "Adicionar um cartão bancário",
    "银行名称": "Nome do banco",
    "银行账户": "Conta bancária",
    "账户姓名": "Nome da conta",
    "请选择银行": "Selecione um banco",
    "银行账户不能为空": "A conta bancária não pode ficar vazia",
    "账户姓名不能为空": "O nome da conta não pode ficar vazio",
    "账户": "Conta",
    "修改银行卡": "Modificar cartão bancário",

    "删除银行卡": "Excluir cartão",
    "+添加银行账户": "+ Adicionar conta bancária",
    "提示": "Dica",
    "确认删除该银行卡吗？": "Tem certeza de que deseja excluir este cartão?",
    "账单": "Conta",
    "时间": "Tempo",
    "账单类型": "Tipo de fatura",
    "充值": "Recarrega",
    "提现": "Retirar o",

    "结算": "Povoado",
    "变化前金额": "Valor antes da mudança",
    "变化后金额": "Valor após a mudança",
    "变化金额": "Alterar valor",
    "备注": "Observação",
    "添加时间": "Tempo extra",
    "修改交易密码": "Modificar senha da transação",
    "新密码": "Nova Senha",
    "确认新密码": "Confirme a nova senha",
    "请输入新密码": "Por favor, digite uma nova senha",

    "请确认新密码": "Por favor, confirme a nova senha",
    "两次密码不一致": "As duas senhas não combinam",
    "手机号码不能为空": "O número de telefone não pode ficar em branco",
    "修改手机号码": "Modificar número de celular",
    "当前绑定的手机号码": "O número de celular atualmente vinculado",
    "新手机号码": "Novo número de celular",
    "货币单位": "Unidade monetária",
    "数字货币充值账单": "Conta de recarga de moeda digital",

    "充值单号": "Número do pedido de recarga",
    "传输网络": "Rede de transporte",
    "应到金额": "Valor devido",
    "实到金额": "Montante atual",
    "法币金额": "Valor Fiat",
    "数字币种名称": "Nome da moeda digital",
    "法定币种名称": "Nome da moeda fiduciária",
    "到账时间": "Tempo de chegada",
    "数字货币提现账单": "Nota Fiscal de Saque em Moeda Digital",

    "提现单号": "Número do pedido de saque",
    "转账网络": "Rede de transferência",
    "申请金额": "Valor da aplicação",
    "手续费": "Taxa de manuseio",
    "到账法币金额": "Valor em moeda fiduciária",
    "到账钱包地址": "Endereço da carteira da conta",
    "到账银行": "Conta bancária",

    "失败时间": "Tempo de falha",
    "完成时间": "Tempo completo",
    "法定货币充值账单": "Conta de recarga de moeda fiduciária",
    "法定货币提现账单": "Nota de retirada de moeda fiduciária",
    "设置": "Configurar",
    "安全中心": "Centro de Segurança",
    "切换语言": "Mudar de idioma",
    "在线客服": "Serviço on-line",

    "退出账号": "Sair",
    "总资产": "Total de ativos",
    "可用余额": "Saldo disponível",
    "委托交易": "Transação confiada",
    "确认退出账号吗？": "Tem certeza que deseja sair?",
    "语言设置": "Opções de linguagem",
    "修改银行卡": "Modificar cartão bancário",
    "请选择银行": "Selecione um banco",
    "银行账户不能为空": "A conta bancária não pode ficar vazia",

    "账户姓名不能为空": "O nome da conta não pode ficar vazio",
    "数字货币充值": "Recarga de moeda digital",
    "法定货币充值": "Recarga de moeda fiduciária",
    "充值金额": "Valor da recarga",
    "充币金额": "Valor do depósito",
    "订单确认": "Confirmação do pedido",
    "数量": "Quantidade",
    "渠道": "Canal",
    "请输入充币金额": "Por favor, insira o valor do depósito",
    "请选择转账网络": "Selecione uma rede de transferência",
    "请输入充值金额": "Insira o valor da recarga",
    "汇率": "Taxa de câmbio",

    "数字货币提现": "Retirada de moeda digital",
    "法定货币提现": "Retirada de moeda fiduciária",
    "提币数量": "Quantidade retirada",
    "地址": "Morada",
    "提现金额": "Quantidade retirada",
    "请输入提币数量": "Por favor, insira o valor da retirada",
    "可用余额不足": "Saldo disponível insuficiente",
    "请输入地址": "Por favor, digite o endereço",
    "请选择银行账户": "Selecione uma conta bancária",
    "请输入提现金额": "Por favor, insira o valor da retirada",
    "手续费减免": "Isenção de taxa",
    "到账金额": "Montante recebido",
    "忘记密码": "Esqueceu a senha",

    "美元": "Dólar",
    "欧元": "EUR",
    "人民币": "RMB",
    "越南盾": "Dong vietnamita",
    "泰铢": "Baht Tailandês",
    "卢比": "Rupia",
    "韩币": "Won coreano",
    "林吉特": "Ringgit",
    "人": "Pessoas",
    "收益": "Renda",

    "DD 天 HH:mm:ss": "DD Day HH:mm:ss",
    "DD 天数 HH:mm:ss": "DD Days HH:mm:ss",
    "交易收入": "receita de transação",
    "预估月收入": "Renda mensal estimada",
    "开始时间": "Hora de início",
    "结束时间": "Fim do tempo",
    "超过可用余额": "Saldo disponível excedido",
    "金额": "Quantia",






};
export { zhPt };