<template>
  <div class="kefu">
    <div class="icon-box">
        <van-icon class="kefu-icon" name="service-o" />
    </div>
  </div>
</template>

<script>
export default {
  name: "kefu",
  data() {
    return{
    }
  }
};
</script>

<style lang="scss" scoped>
    .icon-box{
        width: 40px;
        height: 40px;
        background-color:#345DE9;
        border-radius: 50%;
        text-align: center;
        position: fixed;
        right: 8%;
        bottom: 10%;
    }
    .kefu-icon{
        font-size: 30px;
        line-height: 40px;
        color: #fff;
    }
</style>