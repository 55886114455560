// 西班牙
const zhSp = {
    "欢迎回来!": "¡Bienvenido de nuevo!",
    "手机号": "Número de teléfono",
    "请输入": "Por favor escribe",
    "验证码": "Código de verificación",
    "获取验证码": "Obtener código de verificación",
    "登录": "Iniciar sesión",
    "注册": "Registrarse",
    "请输入手机号码": "Por favor ingrese el número de teléfono",
    "请输入验证码": "Por favor ingrese el código de verificación",

    "加载中": "Cargando",
    "密码": "Clave",
    "邀请码": "Código de invitación",
    "交易密码": "Contraseña de transacción",
    "确定": "Por supuesto",
    "请输入密码": "Por favor, ingrese contraseña",
    "请输入邀请码": "Por favor, introduzca el código de invitación",
    "请输入交易密码": "Por favor ingrese la contraseña de la transacción",

    "买入": "Comprar",
    "可用": "Disponible",
    "委托交易中": "Transacción encomendada",
    "委托金额": "Monto de la comisión",
    "全部": "Todos",
    "预计1年收益可达": "Ingreso esperado de 1 año",
    "大约等于": "Aproximadamente igual a",
    "确认": "Confirmar",
    "您的可用金额为0": "Su cantidad disponible es 0",

    "请输入金额": "Por favor ingrese la cantidad",
    "超过请输入交易密码": "Por favor ingrese la contraseña de la transacción",
    "公告详情": "Detalles del anuncio",
    "公告": "Anuncio",
    "没有更多了": "No más",
    "智能委托市场": "Mercado de comisiones inteligente",
    //翻译周期
    "委托周期": "Ciclo",
    //翻译收益率
    "预期月收益": "Tasa de retorno",

    "市场": "Mercado",
    "团队": "Equipo",
    "交易": "Comercio",
    "钱包": "Cartera",
    "等级": "Calificación",
    "当前等级": "Nivel actual",
    "收益账单": "Factura de ingresos",
    "交易单号": "Número de transacción",

    "收益时间": "Tiempo de beneficio",
    "收益金额": "Cantidad de ingresos",
    "暂无数据": "Sin datos",
    "交易收益": "Ingresos comerciales",
    "总收益": "Los ingresos totales",
    "今日收益": "Ganancias hoy",
    "下一级": "Siguiente nivel",
    "团队收益": "Beneficios del equipo",
    "直推人数": "Número de pulsación directa",

    "团队人数": "Tamaño del equipo",
    "今日新增": "Añadido hoy",
    "邀请好友": "Invitar a amigos",
    "收入排行榜": "Tabla de clasificación de ingresos",
    "ID": "ID",
    "高收益，当然一起赚": "Altos ingresos, por supuesto ganar juntos",
    "邀请好友获得每个直属下属10%的收益,如果您邀请10个朋友,您将获得100%.": "Invita a amigos para obtener el 10% de cada subordinado directo, si invitas a 10 amigos, obtendrás el 100%.",
    "立即邀请": "Invitar ahora",

    "面对面邀请": "Invitación cara a cara",
    "保存图片": "Guardar imagen",
    "邀请码": "Código de invitación",
    "注册链接": "Enlace de registro",
    "享直推1级交易收益": "Disfrute de ingresos comerciales directos de nivel 1",
    "享2-10级交易收益": "Disfrute de ingresos comerciales de nivel 2-10",
    "复制成功": "Copiar con éxito",
    "复制失败": "Error de replicación",

    "下级用户名称": "Nombre de usuario subordinado",
    "下级层级": "Nivel inferior",
    "运行人数": "Número de personas corriendo",
    "注册时间": "Tiempo de registro",
    "机器人列表": "Lista de robots",
    "订单号": "Número de orden",
    "产品名称": "Nombre del producto",
    "天数": "Días",

    "天": "Día",
    "币种": "Divisa",
    "比率": "Relación",
    "购买时间": "Tiempo de compra",
    "到期时间": "Fecha de caducidad",
    "进行中": "Procesando",
    "完成": "Finalizar",
    "添加机器人": "Añadir bot",

    "添加银行卡": "Añadir una tarjeta bancaria",
    "银行名称": "Nombre del banco",
    "银行账户": "Cuenta bancaria",
    "账户姓名": "Nombre de la cuenta",
    "请选择银行": "Seleccione un banco",
    "银行账户不能为空": "La cuenta bancaria no puede estar vacía",
    "账户姓名不能为空": "El nombre de la cuenta no puede estar vacío",
    "账户": "Cuenta",
    "修改银行卡": "Modificar tarjeta bancaria",

    "删除银行卡": "Borrar tarjeta",
    "+添加银行账户": "+ Añadir cuenta bancaria",
    "提示": "Insinuación",
    "确认删除该银行卡吗？": "¿Está seguro de que desea eliminar esta tarjeta?",
    "账单": "Factura",
    "时间": "Tiempo",
    "账单类型": "Tipo de factura",
    "充值": "Recargar",
    "提现": "Retirar",

    "结算": "Asentamiento",
    "变化前金额": "Importe antes del cambio",
    "变化后金额": "Cantidad después del cambio",
    "变化金额": "Cambiar cantidad",
    "备注": "Observación",
    "添加时间": "Agregar tiempo",
    "修改交易密码": "Modificar contraseña de transacción",
    "新密码": "Nueva contraseña",
    "确认新密码": "Confirmar la nueva contraseña",
    "请输入新密码": "Por favor ingrese una nueva contraseña",

    "请确认新密码": "Por favor, confirme la nueva contraseña",
    "两次密码不一致": "Las dos contraseñas no coinciden",
    "手机号码不能为空": "El número de teléfono no puede estar en blanco",
    "修改手机号码": "Modificar número de móvil",
    "当前绑定的手机号码": "El número de móvil vinculado actualmente",
    "新手机号码": "Nuevo número de teléfono móvil",
    "货币单位": "Unidad monetaria",
    "数字货币充值账单": "Factura de recarga de moneda digital",

    "充值单号": "Número de orden de recarga",
    "传输网络": "Red de transporte",
    "应到金额": "Monto adeudado",
    "实到金额": "Cantidad real",
    "法币金额": "Cantidad fiduciaria",
    "数字币种名称": "Nombre de la moneda digital",
    "法定币种名称": "Nombre de la moneda fiduciaria",
    "到账时间": "Hora de llegada",
    "数字货币提现账单": "Factura de retiro de moneda digital",

    "提现单号": "Número de orden de retiro",
    "转账网络": "Red de transferencia",
    "申请金额": "Importe de la solicitud",
    "手续费": "Tasa de tramitación",
    "到账法币金额": "Monto en moneda fiduciaria",
    "到账钱包地址": "Dirección de la billetera de la cuenta",
    "到账银行": "Cuenta bancaria",

    "失败时间": "Tiempo de falla",
    "完成时间": "Tiempo completo",
    "法定货币充值账单": "Factura de recarga de moneda fiduciaria",
    "法定货币提现账单": "Factura de retiro de moneda fiduciaria",
    "设置": "Configurar",
    "安全中心": "Centro de Seguridad",
    "切换语言": "Cambiar de idioma",
    "在线客服": "Servicio en línea",

    "退出账号": "Cerrar sesión",
    "总资产": "Los activos totales",
    "可用余额": "Saldo disponible",
    "委托交易": "Transacción encomendada",
    "确认退出账号吗？": "¿Está seguro de que desea cerrar la sesión?",
    "语言设置": "Ajustes de idioma",
    "修改银行卡": "Modificar tarjeta bancaria",
    "请选择银行": "Seleccione un banco",
    "银行账户不能为空": "La cuenta bancaria no puede estar vacía",

    "账户姓名不能为空": "El nombre de la cuenta no puede estar vacío",
    "数字货币充值": "Recarga de moneda digital",
    "法定货币充值": "Recarga de moneda fiduciaria",
    "充值金额": "Monto de recarga",
    "充币金额": "Cantidad del depósito",
    "订单确认": "Confirmación del pedido",
    "数量": "Cantidad",
    "渠道": "Canal",
    "请输入充币金额": "Por favor ingrese el monto del depósito",
    "请选择转账网络": "Seleccione una red de transferencia",
    "请输入充值金额": "Por favor ingrese el monto de la recarga",
    "汇率": "Tipo de cambio",

    "数字货币提现": "Retiro de moneda digital",
    "法定货币提现": "Retiro de moneda fiduciaria",
    "提币数量": "Cantidad de retiro",
    "地址": "Dirección",
    "提现金额": "Cantidad de retiro",
    "请输入提币数量": "Por favor ingrese el monto del retiro",
    "可用余额不足": "Saldo disponible insuficiente",
    "请输入地址": "Por favor ingrese la dirección",
    "请选择银行账户": "Seleccione una cuenta bancaria",
    "请输入提现金额": "Por favor ingrese el monto del retiro",
    "手续费减免": "Exención de tarifas",
    "到账金额": "Cantidad recibida",
    "忘记密码": "Se te olvidó tu contraseña",

    "美元": "Dólar",
    "欧元": "EUR",
    "人民币": "RMB",
    "越南盾": "Dong vietnamita",
    "泰铢": "Baht tailandés",
    "卢比": "Rupia",
    "韩币": "Coreano ganó",
    "林吉特": "Ringgit",
    "人": "Gente",
    "收益": "Ingreso",

    "DD 天 HH:mm:ss": "DD Day HH:mm:ss",
    "DD 天数 HH:mm:ss": "DD Days HH:mm:ss",
    "交易收入": "ingresos por transacciones",
    "预估月收入": "Ingreso mensual estimado",
    "开始时间": "Tiempo de empezar",
    "结束时间": "Hora de finalización",
    "超过可用余额": "Saldo disponible excedido",
    "金额": "Monto",






};
export { zhSp };